<template>
	<div class="sales-order-table position-relative w-100 h-100 px-4 py-3 d-flex flex-column overflow-hidden">
		<table-summary title="Sales Order" filter="orderNo" sort="issuedAt"
		               v-bind:order="-1"
		               v-bind:fields="fields"
		               v-bind:data-array="salesOrders"
		               v-on:add-new="addOrder"
		               v-on:row-clicked="navigate($event)">
        </table-summary>
	</div>
</template>

<script>
import TableSummary from "@/components/functional/table/TableSummary";
import { format } from "date-fns"

export default {
	name: "SalesOrderTable",

	components: {
		TableSummary,
	},

	data() {
		return {
			fields: [
				{ display: "Order No.", key: "orderNo", classBinding: ["f-2"] },
				{ display: "Client", key: "client", classBinding: ["f-5"] },
				{ display: "Items", key: "items", classBinding: [] },
				{ display: "Currency", key: "currency", classBinding: [] },
				{ display: "Total", key: "totalAmount", classBinding: ["f-2"] },
				{ display: "Issued At", key: "issuedAt", classBinding: [] },
				{ display: "Issued By", key: "issuedBy", classBinding: ["f-2"] },
			],
		}
	},

	computed: {
		salesOrders() {
			return this.$store.state.contents.salesOrders.map(order => {
				const obj = {...order};
				const client = this.$store.state.contents.clients.find(({ _id }) => _id === order.client);
				const issuedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === order.issuedBy);
				obj.client = client ? `${client.name} (${client.clientNo})` : "N/A";
				obj.issuedBy = issuedBy ? `${issuedBy.preferredName} ${issuedBy.lastName}` : "N/A";
				obj.issuedAt = order.issuedAt ? format(new Date(order.issuedAt), "yyyy-MM-dd") : "N/A";
				obj.items = order.items.length; // type Number
				return obj;
			});
		}
	},

	methods: {
		async addOrder() {
			await this.$router.push("/sales/order/add");
		},

		async navigate(dataObj) {
			await this.$router.push(`/sales/order/${dataObj._id}`);
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
